import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import infographic1 from "../../../assets/CCK-Infographic01.png";
import infographic2 from "../../../assets/CCK-Infographic02.png";
import infographic3 from "../../../assets/CCK-Infographic03.png";

const MobileInfoGraphic = () => {
  return (
    <div className="w-full">
      <p>Drag the map to the left and right to see more&nbsp;details.</p>{" "}
      <Swiper
        id={`infographic`}
        spaceBetween={0}
        freeMode={true}
        centeredSlides={true}
        slidesPerView={1.25}
        initialSlide={1}
        pagination={true}
        navigation={true}
        modules={[Pagination, Navigation, FreeMode]}
      >
        <SwiperSlide>
          {" "}
          <img src={infographic1} alt="infographic 1" />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img src={infographic2} alt="infographic 2" />
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <img src={infographic3} alt="infographic 3" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MobileInfoGraphic;
